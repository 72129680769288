import { Ribbon as AlliumRibbon } from '@telus-uds/components-web'
import { mapDataAttrsToDataSet } from '../../../utils/dataAttributes'

export type RibbonProps = {
  text: string
  wrap?: boolean
  top?: number
  left?: number
  purpose?: string
}
const Ribbon = ({ text, wrap = false, top, left, purpose, ...rest }: RibbonProps) => {
  return (
    <AlliumRibbon
      data-testid="allium-ribbon"
      wrap={wrap}
      top={top}
      left={left}
      dataSet={mapDataAttrsToDataSet(rest)}
      variant={{ purpose }}
    >
      {text}
    </AlliumRibbon>
  )
}
export default Ribbon
